import { useEffect, useState } from "react";

import { CloseButton, Col, Container, Row } from "react-bootstrap";

const TopBar = () => {
  let init = true;
  const topBarKey = "topBar_1";
  const [closed, close] = useState(init);

  useEffect(() => {
    if (typeof window !== "undefined" && localStorage && !localStorage?.getItem(topBarKey)) {
      close(false);
    }
  }, []);

  const handleClose = () => {
    close(true);
    localStorage.setItem(topBarKey, new Date().toISOString());
  };

  return (
    <Container fluid>
      <Row className={`bg-info p-1 ${closed ? "d-none" : "d-block"}`}>
        <Col className="text-center">
          <strong>Darmowa dostawa już od 100 PLN!!!</strong>
          <CloseButton className="float-end" onClick={handleClose} />
        </Col>
      </Row>
    </Container>
  );
};

export default TopBar;
