import { useEffect, useState } from "react";

import Image from "next/image";
import Link from "next/link";

import { Button, Col, Container, Row } from "react-bootstrap";

import styles from "./Footer.module.scss";

const Cookie = () => {
  let init = true;
  const [closed, close] = useState(init);

  useEffect(() => {
    if (typeof window !== "undefined" && localStorage && !localStorage?.getItem("cookieAccepted")) {
      close(false);
    }
  }, []);

  const handleClose = () => {
    close(true);
    localStorage.setItem("cookieAccepted", new Date().toISOString());
  };

  if (closed) {
    return <div />;
  }

  return (
    <div className={styles.cookies}>
      <Row>
        <Col xs={12} md={8} xl={10} className="mb-3">
          Ta strona korzysta z ciasteczek aby świadczyć usługi na najwyższym poziomie. Dalsze
          korzystanie ze strony oznacza, że zgadzasz się na ich użycie.
        </Col>
        <Col xs={6} md={2} xl={1} sm={6} className="text-center">
          <Button variant="success" size="lg" onClick={handleClose}>
            Zamknij
          </Button>
        </Col>
        <Col xs={6} md={2} xl={1} sm={6} className="text-center">
          <Link href="/s/polityka-prywatnosci" passHref>
            <Button variant="secondary" size="lg">
              Więcej
            </Button>
          </Link>
        </Col>
      </Row>
    </div>
  );
};

const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={`text-center ${styles.logo}`}>
        <Image src="/logo-text.svg" width={200} height={40} className={styles.svg} alt="logo" />
      </div>
      <div className={styles.main}>
        <Container>
          <Row>
            <Col sm={4} xs={4}>
              <Image src="/logo.svg" className={styles.svg} width={100} height={100} alt="logo" />
              <div className={`${styles.social}`}>
                <a href="https://www.facebook.com/mellowlyproducts/">
                  <i className="bi bi-facebook" />
                </a>
                <a href="https://www.instagram.com/mellowly_products/">
                  <i className="bi bi-instagram" />
                </a>
              </div>
            </Col>
            <Col sm={4} xs={8}>
              <h5>Pomoc</h5>
              <ul>
                <li>
                  <Link href="/s/polityka-prywatnosci">Polityka prywatności</Link>
                </li>
                <li>
                  <Link href="/s/regulamin">Regulamin sklepu</Link>
                </li>
              </ul>
            </Col>
            <Col sm={{ offset: 0, span: 4 }} xs={{ offset: 4, span: 8 }}>
              <h5>Obsługa klienta</h5>
              <ul>
                <li>
                  <Link href="/s/dostawa-platnosc">
                    <a>Dostawa i płatności</a>
                  </Link>
                </li>
                <li>
                  <Link href="/kontakt">
                    <a>Kontakt</a>
                  </Link>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className={styles.copyright}>
        <Container>
          <Row>
            <Col md={4}>© Mellowly 2021</Col>
            <Col md={5} className={`text-center ${styles.payments}`}>
              <Image
                src="/payments/mastercard.svg"
                width={30}
                height={25}
                title="Mastercard"
                className={styles.svg}
                alt="mastercard-logo"
              />
              <Image
                src="/payments/visa.svg"
                width={30}
                height={25}
                title="Visa"
                className={styles.svg}
                alt="visa-logo"
              />
              <Image
                src="/payments/paynow-white.png"
                height={25}
                width={60}
                alt="paynow-logo"
                title="Paynow"
              />
              <Image src="/payments/blik.svg" height={25} width={50} alt="blik-logo" title="BLIK" />
              <Image
                src="/payments/google.svg"
                height={40}
                width={70}
                alt="google-pay-logo"
                title="Google Pay"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Cookie />
    </footer>
  );
};

export default Footer;
