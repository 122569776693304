import { useState } from "react";

import Image from "next/image";
import Link from "next/link";

import { Button, Container } from "react-bootstrap";
import useSWR from "swr";

import { fetcher } from "../../api/fetcher";

import styles from "./Navbar.module.scss";

const NavbarComponent = () => {
  const { data, error } = useSWR("/api/cart", fetcher);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const cartItems =
    !error && data
      ? Object.values(data.items || {}).reduce((p, c) => {
          p += c.quantity;
          return p;
        }, 0)
      : "0";

  const linksLeft = [
    <Link key="home" href="/" passHref>
      <a onClick={handleClose}>Home</a>
    </Link>,
    <Link key="shop" href="/sklep/swiece-sojowe" passHref>
      <a onClick={handleClose}>Sklep</a>
    </Link>,
  ];

  return (
    <Container>
      <header className={styles.header}>
        <nav className={styles.left}>{linksLeft}</nav>

        <Link href="/" passHref>
          <a className={styles.logoLink}>
            <Image src="/logo-text.svg" width={200} height={121} alt="logo" />
          </a>
        </Link>

        <div className={styles.right}>
          <Link href="/koszyk" passHref>
            <a>
              <i className="bi bi-bag" /> ({cartItems})
            </a>
          </Link>
          <a>
            <i className="bi bi-search" />
          </a>
        </div>

        <div className={styles.mobile}>
          <Link href="/koszyk" passHref>
            <a className={styles.cart}>
              <i className="bi bi-bag" /> ({cartItems})
            </a>
          </Link>
          <Button onClick={() => (show ? handleClose() : handleShow())}>
            <i className={`bi bi-${show ? "x" : "list"}`} />
          </Button>
        </div>
      </header>
      <div className={styles.mobileMenu} style={{ display: show ? "block" : "none" }}>
        {linksLeft}
      </div>
    </Container>
  );
};

export default NavbarComponent;
